var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('InnerToolTip', _vm._g(_vm._b({
    ref: "tooltip",
    on: {
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm._t("activator", null, null, {
          on: on,
          attrs: attrs
        })];
      }
    }], null, true)
  }, 'InnerToolTip', _vm.attributes, false), _vm.$listeners), [_vm._v(" "), _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };