/* unplugin-vue-components disabled */var render, staticRenderFns
import script from "./HoverableTooltip.vue?vue&type=script&lang=js"
export * from "./HoverableTooltip.vue?vue&type=script&lang=js"
import style0 from "./HoverableTooltip.vue?vue&type=style&index=0&id=3645507c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3645507c",
  null
  
)

export default component.exports