import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.number.constructor.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { VTooltip } from 'vuetify/lib';
export default VTooltip.extend({
  props: _objectSpread(_objectSpread({}, VTooltip.options.props), {}, {
    hoverable: {
      type: Boolean,
      default: false
    },
    closeDelay: {
      type: [Number, String],
      default: 50
    }
  }),
  data: function data() {
    return {
      customListeners: {}
    };
  },
  watch: {
    openOnHover: 'resetActivator',
    hoverable: 'resetActivator'
  },
  mounted: function mounted() {
    this.customListeners = this.genCustomListeners();
  },
  methods: {
    genCustomListeners: function genCustomListeners() {
      var _this = this;
      if (this.disabled) return {};
      var listeners = {};
      if (this.openOnHover && this.hoverable) {
        listeners.mouseenter = function (e) {
          _this.runDelay('open');
        };
        listeners.mouseleave = function (e) {
          _this.runDelay('close');
        };
      }
      return listeners;
    },
    resetActivator: function resetActivator() {
      this.customListeners = this.genCustomListeners();
    },
    getContentSlot: function getContentSlot() {
      return [this.$createElement('div', {
        staticClass: 'v-tooltip__content__inner',
        on: _objectSpread({}, this.customListeners)
      }, VTooltip.options.methods.getContentSlot.call(this))];
    }
  }
});