import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _vm.tooltip ? _c('FloatingTooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c(VBtn, _vm._g({
          key: "icon-btn",
          attrs: {
            "color": _vm.color,
            "icon": "",
            "x-small": _vm.xSmall,
            "small": !_vm.xSmall
          }
        }, _objectSpread(_objectSpread({}, _vm.$listeners), on)), [_vm.icon ? _c(VIcon, {
          attrs: {
            "small": _vm.xSmall
          }
        }, [_vm._v(_vm._s(_vm.icon))]) : _vm._t("default")], 2)];
      }
    }], null, false, 4219220779)
  }, [_vm._v(" "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.tooltip))])]) : _c(VBtn, _vm._g({
    key: "icon-btn",
    attrs: {
      "color": _vm.color,
      "icon": "",
      "x-small": _vm.xSmall,
      "small": !_vm.xSmall
    }
  }, _vm.$listeners), [_vm.icon ? _c(VIcon, {
    attrs: {
      "small": _vm.xSmall
    }
  }, [_vm._v(_vm._s(_vm.icon))]) : _vm._t("default")], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };